/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
import values from 'lodash.values';
import { dateToYYYYMMDD } from '../../scripts/util';

const totalLabels = [
  'Total Income',
  'Total Cost of Sales',
  'Gross Profit',
  'Total Operating Expenses',
  'Net Profit',
] as const;

export const scrubReportData = (data: any[]): ScrubbedReportData => {
  const categoryRows: CategoryMap[] = [];
  let reportDateFull = '';
  const totals: Totals = {};

  data.forEach((row: object) => {
    const rowVals = values(row).splice(0, 2);
    // Jankily find the row with the report date range.
    if (
      !reportDateFull &&
      typeof rowVals[0] === 'string' &&
      rowVals[0].includes('For the') &&
      rowVals[0].includes('ended')
    ) {
      reportDateFull = rowVals[0];
    } else if (typeof rowVals[0] === 'string' && totalLabels.includes(rowVals[0])) {
      totals[rowVals[0]] = rowVals[1];
    } else if (rowVals.length === 2 && !isNaN(rowVals[1])) {
      // Ignore anything without 2 columns (code + amount)
      categoryRows.push({ ledgerAccount: rowVals[0], value: rowVals[1] });
    }
  });

  let reportType: ReportType = null;
  if (reportDateFull.indexOf('12 months') !== -1) {
    reportType = 'year';
  } else if (reportDateFull.indexOf('month ended') !== -1) {
    reportType = 'month';
  }

  let reportDate;
  if (reportDateFull) {
    const dateString = reportDateFull.split('ended ').pop();
    reportDate = dateToYYYYMMDD(dateString);
  }

  return {
    categoryRows,
    reportDateFull,
    totals,
    reportType,
    reportDate,
  };
};

type Totals = { [key in typeof totalLabels[number]]?: number };
type CategoryMap = { ledgerAccount: string; value: number };
type ReportType = 'year' | 'month' | null;

export type ScrubbedReportData = {
  categoryRows: CategoryMap[];
  reportDateFull: string;
  reportDate: string;
  totals: Totals;
  reportType: ReportType;
};
