export const formatNumberToNZD = (value: number): string => {
  return new Intl.NumberFormat('en-NZ', { style: 'currency', currency: 'NZD' }).format(value);
};

export const categories = [
  'Sales',
  'Cost of Sales Labour',
  'Cost of Sales Materials',
  'Cost of Sales Other',
  'Motor Vehicles',
  'Phones',
  "Don't Allocate",
];

export const dateToYYYYMMDD = (dateString: string | undefined): string => {
  if (!dateString) {
    return '';
  }
  const date = new Date(dateString);
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0];
};

// todo share this with katipolt if possible
export function round(value, decimalPlaces = 2) {
  const decimals = 10 ** decimalPlaces;
  return Math.round(value * decimals) / decimals;
}
