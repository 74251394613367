import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  page: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100%',
    width: '100%',
    overflow: 'auto',
    padding: 40,
    maxWidth: 1200,
  },
  '@media (max-width: 480px)': {
    page: {
      padding: 0,
    },
  },
  paper: {
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
});

export const Page: React.FC = props => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <Paper className={classes.paper} elevation={4}>
        {children}
      </Paper>
    </div>
  );
};
