/* eslint-disable prefer-destructuring */
import { UserAgentApplication } from 'msal';
import { environment } from './environment';

const hostname = window?.location?.hostname;
let tenantId;

const { dev, uat, production } = environment;
switch (hostname) {
  case 'import-dev.tradebusinesstraining.com':
  case 'busdevimportstaticdev.z8.web.core.windows.net': {
    window.clientId = dev.clientId;
    tenantId = dev.tenantId;
    window.apiURL = dev.apiUrl;
    break;
  }
  case 'localhost': {
    window.clientId = dev.clientId;
    tenantId = dev.tenantId;
    window.apiURL = 'http://localhost:7071';
    window.aadScope = dev.apiUrl;
    break;
  }
  case 'import-uat.tradebusinesstraining.com':
  case 'busdevimportstaticuat.z8.web.core.windows.net': {
    window.clientId = uat.clientId;
    tenantId = uat.tenantId;
    window.apiURL = uat.apiUrl;
    break;
  }
  case 'import.tradebusinesstraining.com': {
    window.clientId = production.clientId;
    tenantId = production.tenantId;
    window.apiURL = production.apiUrl;
    break;
  }
  default: {
    break;
  }
}

const authority = `https://login.microsoftonline.com/${tenantId}`;

export const GRAPH_SCOPES = {
  OPENID: 'openid',
};

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [GRAPH_SCOPES.OPENID],
  },
};

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: window.clientId,
    authority,
    validateAuthority: true,
    postLogoutRedirectUri: '',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
  },
});

export const requiresInteraction = errorMessage => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

export const acquireToken = async (request, redirect = false) => {
  return msalApp.acquireTokenSilent(request).catch(error => {
    if (requiresInteraction(error.errorCode)) {
      return redirect ? msalApp.acquireTokenRedirect(request) : (msalApp.acquireTokenPopup(request) as any);
    }

    throw new Error('Failed to acquire token');
  });
};
