export const base = {
  blue: '#2F4054',
  white: '#FDFFFC',
  green: '#239288',
  red: '#E71D36',
  orange: '#DF6434',
  yellow: '#FDC521',
  yellowLight: '#fef1cc',
  background: '#eee',
  statusSuccess: '#83b228',
  statusInfo: '#2089CA',
  statusWarning: '#fe9600',
  statusDanger: '#cb2139',
};

export const primary = {
  main: base.blue,
  light: '#2089CA',
  dark: '#222e3d',
  contrastText: base.white,
};

export const secondary = {
  main: base.orange,
  light: '#F47902',
  dark: '#c44202',
  contrastText: '#fff',
  lighter: '#eba285',
};

export const error = {
  main: base.red,
  light: '#ffdce0',
  contrastText: base.white,
};
