/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableCell,
  TableRow,
  makeStyles,
  TableContainer,
  TextField,
  Checkbox,
  TableBody,
  InputAdornment,
  TableCellProps,
} from '@material-ui/core';
import { TotalsMap } from '../../../../api/tbt/shared/types';
import { categories, round, formatNumberToNZD } from '../../scripts/util';
import { ScrubbedReportData } from './xeroReportHelper';

const standardOwnerDrawingsPerAnnum = 90000;

const calculateTotals = (
  reportData: ScrubbedReportData,
  categoryMap: { [key: string]: string },
  adminLabour: number | null,
  ownerIncomeIncluded: boolean,
  numberOfOwners: number | null,
  ownersLabourPercentage: number | null,
  ownersAmountIncludedInOpLabour: number | null,
): TotalsMap => {
  const categoryToTotalMap: { [key: string]: number } = {};
  numberOfOwners = (numberOfOwners ?? 0) as number;
  ownersLabourPercentage = (ownersLabourPercentage ?? 0) as number;
  ownersAmountIncludedInOpLabour = (ownersAmountIncludedInOpLabour ?? 0) as number;
  adminLabour = (adminLabour ?? 0) as number;

  categories.forEach(c => {
    categoryToTotalMap[c] = 0;
  });

  Object.keys(categoryMap).forEach((key: string) => {
    categoryToTotalMap[categoryMap[key]] += reportData?.categoryRows?.find(cr => cr.ledgerAccount === key)?.value ?? 0;
  });

  let ownerOperationalLabour = 0;
  if ((!ownerIncomeIncluded && numberOfOwners > 0) || ownerIncomeIncluded) {
    const ownersDrawingsTotal =
      (reportData.reportType === 'month' ? standardOwnerDrawingsPerAnnum / 12 : standardOwnerDrawingsPerAnnum) *
      numberOfOwners;
    const averageOwnerLabourPercentage = round((ownersLabourPercentage * numberOfOwners) / numberOfOwners);
    ownerOperationalLabour = round(averageOwnerLabourPercentage * 0.01 * ownersDrawingsTotal);
  }

  let opLabourCost = round(categoryToTotalMap['Cost of Sales Labour'] - adminLabour + ownerOperationalLabour);
  if (ownerIncomeIncluded) {
    opLabourCost -= ownersAmountIncludedInOpLabour;
  }

  const totals: TotalsMap = {
    Turnover: categoryToTotalMap.Sales,
    'Materials cost': categoryToTotalMap['Cost of Sales Materials'],
    'Other Direct costs': categoryToTotalMap['Cost of Sales Other'],
    'Op. Labour cost': opLabourCost,
    'Vehicles and phones': categoryToTotalMap['Motor Vehicles'] + categoryToTotalMap.Phones,
  } as TotalsMap;

  totals['Gross profit'] = round(
    categoryToTotalMap.Sales -
      (totals['Materials cost'] +
        totals['Op. Labour cost'] +
        totals['Other Direct costs'] +
        totals['Vehicles and phones']),
  );

  return totals;
};

const useStyles = makeStyles({
  tableContainer: {
    width: 400,
    marginTop: 20,
    marginBottom: 20,
  },
});

export const ProfitLossReportSummary: React.FC<ProfitLossReportSummaryProps> = props => {
  const { title, reportData, categoryMap, totals, setTotals } = props;
  const [adminLabour, setAdminLabour] = useState<number | null>(0);
  const [ownerIncomeIncluded, setOwnerIncomeIncluded] = useState(false);
  const [numberOfOwners, setNumberOfOwners] = useState<number | null>(1);
  const [ownersAmountIncludedInOpLabour, setOwnersAmountIncludedInOpLabour] = useState<number | null>(0);
  const [ownersLabourPercentage, setOwnersLabourPercentage] = useState<number | null>(0);

  const classes = useStyles();

  const defaultCellProps: TableCellProps = {
    size: 'small',
  };

  useEffect(() => {
    setTotals(
      calculateTotals(
        reportData,
        categoryMap,
        adminLabour,
        ownerIncomeIncluded,
        numberOfOwners,
        ownersLabourPercentage,
        ownersAmountIncludedInOpLabour,
      ),
    );
  }, [ownerIncomeIncluded, adminLabour, numberOfOwners, ownersLabourPercentage, ownersAmountIncludedInOpLabour]);

  return (
    <>
      <Typography variant="h4">{title}</Typography>
      <TableContainer className={classes.tableContainer}>
        <Table size="small">
          <TableBody>
            {Object.keys(totals).map(key => (
              <TableRow key={key}>
                <TableCell {...defaultCellProps}>
                  <Typography>{key}</Typography>
                </TableCell>
                <TableCell {...defaultCellProps}>
                  <Typography>{formatNumberToNZD(totals[key])}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography>
                What amount in the Operational Labour cost is associated with an admin person or administration and
                sales work?
              </Typography>
            </TableCell>
            <TableCell>
              <TextField
                fullWidth
                type="number"
                value={adminLabour}
                onChange={event => {
                  setAdminLabour(event.target.value ? Number(event.target.value) : null);
                }}
                inputProps={{
                  style: { textAlign: 'right' },
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography>{"Is the owner's income included in the Operational Labour cost?"}</Typography>
            </TableCell>
            <TableCell>
              <Checkbox
                size="small"
                checked={ownerIncomeIncluded}
                onChange={event => {
                  setOwnerIncomeIncluded(event.target.checked);
                }}
                name="ownerIncome"
                color="primary"
              />
            </TableCell>
          </TableRow>
          {ownerIncomeIncluded && (
            <TableRow>
              <TableCell>
                <Typography>What amount of the owners income is included in the Op. Labour cost?</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  type="number"
                  value={ownersAmountIncludedInOpLabour}
                  onChange={e => {
                    setOwnersAmountIncludedInOpLabour(e.target.value ? Number(e.target.value) : null);
                  }}
                  inputProps={{
                    style: { textAlign: 'right' },
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <Typography>Standard owner drawings</Typography>
            </TableCell>
            <TableCell>
              <Typography>{formatNumberToNZD(standardOwnerDrawingsPerAnnum)}</Typography>
            </TableCell>
          </TableRow>
          {!ownerIncomeIncluded && (
            <TableRow>
              <TableCell>
                <Typography>Number of owners</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  inputProps={{
                    style: { textAlign: 'right' },
                  }}
                  fullWidth
                  type="number"
                  value={numberOfOwners}
                  onChange={e => {
                    setNumberOfOwners(e.target.value ? Number(e.target.value) : null);
                  }}
                />
              </TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell>
              <Typography>
                {"What % of the owner's time is considered operational (chargeable to jobs vs admin/sales)?"}
              </Typography>
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                value={ownersLabourPercentage}
                error={(ownersLabourPercentage ?? 0) > 100}
                onChange={e => {
                  setOwnersLabourPercentage(e.target.value ? Number(e.target.value) : null);
                }}
                inputProps={{
                  style: { textAlign: 'right' },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
                helperText="If more than 1 owner, please average between owners"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

interface ProfitLossReportSummaryProps {
  title: string;
  reportData: ScrubbedReportData;
  categoryMap: { [key: string]: string };
  totals: TotalsMap;
  setTotals: (totals: TotalsMap) => void;
}
