import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as MaterialMuiThemeProvider } from '@material-ui/styles';
import { theme } from './theme';
import Home from './components/home';

ReactDOM.render(
  <CssBaseline>
    <MaterialMuiThemeProvider theme={theme}>
      <Home />
    </MaterialMuiThemeProvider>
  </CssBaseline>,
  document.getElementById('root'),
);
