import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { AppBar as MUIAppBar, Button, Toolbar, Typography, Box } from '@material-ui/core';
import tradebusinessLogo from '../assets/images/trade-business-logo.svg';

const useStyles = makeStyles({
  appBar: {
    backgroundColor: '#FDFFFC',
  },
  toolbar: {
    justifyContent: 'space-between',
    paddingLeft: 10,
    color: 'black',
  },
  logo: {
    height: 40,
  },
  logoutButton: {
    marginLeft: 20,
  },
});

export const AppBar: React.FC<AppBarProps> = props => {
  const classes = useStyles();
  const { username, onSignOut } = props;
  return (
    <MUIAppBar className={classes.appBar} position="static">
      <Toolbar classes={{ root: classes.toolbar }} variant="dense">
        <img className={classes.logo} src={tradebusinessLogo} />
        <Box display="flex" alignItems="center">
          <Typography variant="h6">{username}</Typography>
          <Button className={classes.logoutButton} onClick={onSignOut} variant="outlined">
            Logout
          </Button>
        </Box>
      </Toolbar>
    </MUIAppBar>
  );
};

interface AppBarProps {
  username: string;
  onSignOut: (() => void) | undefined;
}
