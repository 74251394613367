import React from 'react';
import {
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  TextField,
  TableCellProps,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Categories } from '../../../api/tbt/shared/types';
import { formatNumberToNZD, categories } from '../scripts/util';
import { ScrubbedReportData } from './pages/xeroReportHelper';

const useStyles = makeStyles({
  categorySelector: {
    width: 200,
  },
  row: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    display: 'flex',
    alignItems: 'center',
  },
  firstCell: {
    width: 220,
  },
  table: {},
  tableContainer: {
    marginTop: 20,
    marginBottom: 5,
  },
  tableCell: {
    fontSize: '0.8rem',
    paddingTop: 0,
    paddingBottom: 0,
  },
  head: {
    height: 40,
  },
});

export const ReportCategoriser: React.FC<ReportCategoriserProps> = props => {
  const { reportData, categoryMap, updateCategoryMap } = props;
  const classes = useStyles();

  const renderCategorySelector = ledgerCode => {
    return (
      <Autocomplete
        options={categories}
        value={categoryMap[ledgerCode]}
        onChange={(e, v) => updateCategoryMap(ledgerCode, v)}
        disableClearable
        renderInput={params => <TextField {...params} label="" margin="normal" placeholder="Enter a Category" />}
      />
    );
  };

  const defaultCellProps: TableCellProps = {
    classes: { root: classes.tableCell },
    size: 'small',
  };
  return (
    <>
      <Typography variant="h4">{`Profit and Loss ${reportData?.reportDateFull ?? ''}`}</Typography>
      <Typography variant="subtitle1">Select the applicable category for each line</Typography>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table stickyHeader className={classes.table} size="small">
          <TableHead>
            <TableRow classes={{ head: classes.head }}>
              <TableCell {...defaultCellProps} className={classes.firstCell}>
                Ledger Account
              </TableCell>
              <TableCell {...defaultCellProps}>Profit and Loss Category</TableCell>
              <TableCell {...defaultCellProps} align="right">
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData !== undefined &&
              reportData?.categoryRows.map(row => (
                <TableRow key={row.ledgerAccount}>
                  <TableCell {...defaultCellProps}>{row.ledgerAccount}</TableCell>
                  <TableCell {...defaultCellProps}>{renderCategorySelector(row.ledgerAccount)}</TableCell>
                  <TableCell {...defaultCellProps} align="right">
                    {formatNumberToNZD(row.value)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

interface ReportCategoriserProps {
  reportType: 'ProfitAndLoss';
  reportData: ScrubbedReportData | null;
  categoryMap: { [key: string]: keyof typeof Categories };
  updateCategoryMap: (ledgerCode, value) => void;
}
