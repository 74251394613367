import { createMuiTheme } from '@material-ui/core';
import { primary, secondary, error } from '@katipolt/core/constants/colors';

export const theme = createMuiTheme({
  typography: {
    fontSize: 12,
    fontFamily: ['Roboto', 'Montserrat', 'Helvetica Neue', 'sans-serif'].join(','),
  },
  palette: {
    primary,
    secondary,
    error,
  },
});
