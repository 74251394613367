import React, { useState, useEffect, useRef } from 'react';
import {
  Stepper,
  Typography,
  Step,
  StepLabel,
  Button,
  makeStyles,
  Snackbar,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { read, WorkBook, utils } from 'xlsx';
import { Page } from '../page';
import { FileDropper } from '../fileDropper';
import { ReportCategoriser } from '../reportCategoriser';
import xeroExportImage from '../../assets/images/xero-export.png';
import { scrubReportData, ScrubbedReportData } from './xeroReportHelper';
import { saveProfitAndLoss, getUserPreferences, saveUserPreferences } from '../../scripts/api';
import { ProfitLossReportSummary } from './profitLossReportSummary';
import { TotalsMap, TotalsData, UserConfiguration } from '../../../../api/tbt/shared/types';

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(1),
    width: 130,
    height: 50,
  },
  stepContent: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  stepContentWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  fileDropper: {
    marginTop: 20,
    marginBottom: 20,
    height: 400,
  },
  xeroExport: {
    width: 350,
    marginTop: 40,
  },
}));

const steps = ['Introduction', 'Import P&L Report', 'Categorise Your Report', 'Review and Save'];
export const ImportReport: React.FC = () => {
  const classes = useStyles();
  const [reportData, setReportData] = useState<ScrubbedReportData | null>(null);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [activeStep, setActiveStep] = React.useState(0);
  const [categoryMap, setCategoryMap] = useState({}); // todo will need to default undefineds to be null to avoid uncontrolled MUI error
  const [loading, setLoading] = useState(false);
  const [totals, setTotals] = useState<TotalsMap>({} as any);
  const [showDialog, setShowDialog] = useState(false);
  const yearlyReportsConfigured = useRef([] as string[]);

  useEffect(() => {
    setLoading(true);
    getUserPreferences()
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to load categories. Try logging out then in again');
        }
        return response.json();
      })
      .then((result: UserConfiguration) => {
        setCategoryMap(result.CategoryMap);
        yearlyReportsConfigured.current = result.YearlyReportsConfigured;
      })
      .catch(e => {
        setSnackbarMessage('Failed to load categories.  Try logging out then in again.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const renderIntroduction = () => {
    return (
      <>
        <Typography variant="h4">Export the Profit and Loss report</Typography>
        <Typography variant="subtitle1">
          To get started open up your financial software and export your Profit and Loss report to excel. This will be
          used in the next step to categorise the lines.
        </Typography>
        <Box display="flex" justifyContent="center">
          <img className={classes.xeroExport} src={xeroExportImage} />
        </Box>
      </>
    );
  };

  const saveCategoryTotals = async () => {
    setLoading(true);
    const promises: Promise<Response>[] = [];
    if (!yearlyReportsConfigured.current.includes(reportData!.reportDate)) {
      yearlyReportsConfigured.current.push(reportData!.reportDate);
    }
    promises.push(
      saveUserPreferences({
        CategoryMap: categoryMap,
        YearlyReportsConfigured: yearlyReportsConfigured.current,
      }),
    );

    const data: TotalsData = {
      totals,
      reportType: reportData!.reportType,
      date: reportData!.reportDate,
    };

    promises.push(
      saveProfitAndLoss(data).then(response => {
        if (!response.ok) {
          throw new Error('Failed to save updated metrics');
        }
        return response.json();
      }),
    );

    return Promise.all(promises)
      .finally(() => {
        setLoading(false);
      })
      .catch(e => {
        setSnackbarMessage(e.message);
        return Promise.reject();
      });
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      saveCategoryTotals().then(() => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      });
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setReportData(null);
    setActiveStep(0);
  };

  const fileDropRejected = (files: File[]) => {
    setSnackbarMessage(`Failed to upload '${files[0]?.name}'.  File type must be .xls or .xlsx`);
  };

  const parseReport = (files: File[]) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = new Uint8Array(e.target.result);
      const workbook: WorkBook = read(data, { type: 'array' });
      const scrubbedReportData: ScrubbedReportData = scrubReportData(
        utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]),
      );
      if (!scrubbedReportData.reportType) {
        setSnackbarMessage('Report must be a monthly or yearly Profit and Loss report');
      } else if (
        scrubbedReportData.reportType === 'year' &&
        yearlyReportsConfigured.current.includes(scrubbedReportData.reportDate)
      ) {
        setReportData(scrubbedReportData);
        setShowDialog(true);
      } else {
        setReportData(scrubbedReportData);
        handleNext();
      }
    };
    reader.readAsArrayBuffer(files[0]);
  };

  const updateCategoryMap = (ledgerCode, value) => {
    setCategoryMap(previousCategoryMap => {
      return {
        ...previousCategoryMap,
        [ledgerCode]: value,
      };
    });
  };

  const renderFileUpload = () => {
    return (
      <>
        <Typography variant="h4">Import Profit and Loss Report</Typography>
        <FileDropper
          onDropAccepted={parseReport}
          onDropRejected={fileDropRejected}
          className={classes.fileDropper}
          supportedFileTypes="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .xls, .xlsx"
        />
      </>
    );
  };

  const renderReportSummaryScreen = () => {
    return (
      <ProfitLossReportSummary
        title={`Profit and Loss ${reportData?.reportDateFull ?? ''}`}
        categoryMap={categoryMap}
        reportData={reportData as ScrubbedReportData}
        totals={totals}
        setTotals={setTotals}
      />
    );
  };

  const getStepContent = step => {
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      );
    }
    switch (step) {
      case 0:
        return renderIntroduction();
      case 1:
        return renderFileUpload();
      case 2:
        return (
          <ReportCategoriser
            reportData={reportData}
            reportType="ProfitAndLoss"
            categoryMap={categoryMap}
            updateCategoryMap={updateCategoryMap}
          />
        );
      case 3:
        return renderReportSummaryScreen();
      default:
        return 'Unknown step';
    }
  };

  const dismissDialog = () => {
    setReportData(null);
    setShowDialog(false);
  };

  const isNextButtonDisabled = () => {
    return (
      loading ||
      (activeStep === 1 && !reportData?.categoryRows?.length) ||
      (activeStep === 2 &&
        reportData?.categoryRows.some(r => {
          return !Object.keys(categoryMap).includes(r?.ledgerAccount);
        }))
    );
  };

  return (
    <Page>
      <Stepper activeStep={activeStep}>
        {steps.map(label => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <div className={classes.stepContentWrapper}>
        {activeStep === steps.length ? (
          <Box display="flex" height="100%" width="100%" justifyContent="space-between" flexDirection="column">
            <Typography variant="h4">Import complete - you can now view your updated metrics in busdev</Typography>
            <div className={classes.toolbar}>
              <Button variant="contained" color="primary" onClick={handleReset} className={classes.button}>
                Reset
              </Button>
            </div>
          </Box>
        ) : (
          <>
            <div className={classes.stepContent}>{getStepContent(activeStep)}</div>
            <div className={classes.toolbar}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button} variant="outlined">
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
                disabled={isNextButtonDisabled()}
              >
                {activeStep === steps.length - 1 ? 'Finish & Save' : 'Next'}
              </Button>
            </div>
          </>
        )}
      </div>
      <Snackbar
        open={snackbarMessage != null}
        autoHideDuration={6000}
        onClose={() => {
          setSnackbarMessage(null);
        }}
      >
        <MuiAlert
          variant="filled"
          onClose={() => {
            setSnackbarMessage(null);
          }}
          severity="error"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <Dialog
        open={showDialog}
        onClose={dismissDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{'Re-categorise yearly report?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`You have previously categorised and submitted a yearly report ${reportData?.reportDateFull}. Do you wish to overwrite your previous submission?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={dismissDialog} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              setShowDialog(false);
              handleNext();
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
