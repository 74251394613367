import { hot } from 'react-hot-loader';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Box } from '@material-ui/core';
import { Content } from './content';
import { AppBar } from './appBar';
import AuthProvider, { AuthProviderProps } from './authProvider';
import 'babel-polyfill';

const useStyles = makeStyles({
  home: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const Home: React.FC<AuthProviderProps> = props => {
  const classes = useStyles();
  const { account, onSignOut } = props;
  if (!account) {
    return (
      <Box display="flex" height="100%" width="100%" justifyContent="center" alignItems="center">
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <div className={classes.home}>
      <AppBar username={account?.name ?? ''} onSignOut={onSignOut} />
      <div className={classes.content}>
        <Content />
      </div>
    </div>
  );
};

declare const module: any;
export default AuthProvider(hot(module)(Home));
