import React, { Component } from 'react';
import { Account } from 'msal';
import { msalApp, GRAPH_REQUESTS, requiresInteraction } from '../scripts/auth-utils';

export default C =>
  class AuthProvider extends Component<AuthProviderProps, AuthProviderState> {
    constructor(props) {
      super(props);

      this.state = {
        account: null,
        error: null,
      };
    }

    onSignIn = async () => {
      return msalApp.loginRedirect(GRAPH_REQUESTS.LOGIN);
    };

    onSignOut = () => {
      msalApp.logout();
    };

    componentDidMount = async () => {
      msalApp.handleRedirectCallback(error => {
        if (error) {
          const errorMessage = error.errorMessage ? error.errorMessage : 'Unable to acquire access token.';
          this.setState({
            error: errorMessage,
          });
        }
      });

      const account = msalApp.getAccount();
      if (account) {
        this.setState({
          account,
        });
      } else {
        this.onSignIn();
      }
    };

    render() {
      const { account, error } = this.state;
      return (
        <C
          {...this.props}
          account={account}
          error={error}
          onSignIn={() => this.onSignIn()}
          onSignOut={() => this.onSignOut()}
        />
      );
    }
  };

export interface AuthProviderState {
  account: Account | null;
  error: any;
}

export interface AuthProviderProps {
  account?: Account | null;
  error?: any;
  onSignIn?: () => void;
  onSignOut?: () => void;
}
