export const environment: env = {
  dev: {
    clientId: '7b96aa84-dfd4-42dc-8e26-9e918836bc35',
    tenantId: 'bf1f10cd-0fa6-481e-ac8a-18ff4caa1949',
    apiUrl: 'https://katipolt-tbt-dev.azurewebsites.net',
  },
  uat: {
    clientId: '55f745bd-e789-4068-8ca0-1257f065c295',
    tenantId: '205a9311-0273-49b1-9323-c653aa160d5a',
    apiUrl: 'https://katipolt-tbt-uat.azurewebsites.net',
  },
  production: {
    clientId: 'b50a02f3-12f5-435c-8113-5333db6b292c',
    tenantId: 'ec766f2a-87fa-45cf-a035-cd14adb91dfa',
    apiUrl: 'https://katipolt-tbt-prod.azurewebsites.net',
  },
};

type envParams = {
  clientId: string;
  tenantId: string;
  apiUrl: string;
};

type env = {
  dev: envParams;
  uat: envParams;
  production: envParams;
};
