import { msalApp, acquireToken } from './auth-utils';
import { TotalsData, UserConfiguration } from '../../../api/tbt/shared/types';

async function getToken(): Promise<string> {
  const account = await msalApp.getAccount();
  const token =
    account &&
    (await acquireToken({
      scopes: [`${window.aadScope ?? window.apiURL}/user_impersonation`],
    }));
  return `Bearer ${token.accessToken}`;
}

export const post = async (path, body: any) => {
  const auth = await getToken();

  return fetch(`${window.apiURL}/${path}`, {
    method: 'POST',
    headers: new Headers({
      Authorization: auth,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(body),
  });
};

export const get = async path => {
  const auth = await getToken();

  return fetch(`${window.apiURL}/${path}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: auth,
      'Content-Type': 'application/json',
    }),
  });
};

export const saveProfitAndLoss = async (data: TotalsData) => {
  return post('api/SaveProfitLossMetrics', data);
};

export const saveUserPreferences = async (data: UserConfiguration) => {
  return post('api/SaveUserPreferences', data);
};

export const getUserPreferences = async () => {
  return get('api/GetUserPreferences');
};
