import React from 'react';
import { ImportReport } from './pages/importReport';

export const Content = () => {
  return (
    <>
      <ImportReport />
    </>
  );
};
