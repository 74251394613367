import React, { useMemo } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { Typography } from '@material-ui/core';

const baseStyle: any = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 4,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#9e9b9b',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '100%',
  justifyContent: 'center',
  cursor: 'pointer',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

export const FileDropper: React.FC<FileDropperProps> = props => {
  const { onDropAccepted, onDropRejected, className, supportedFileTypes } = props;
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: supportedFileTypes,
    onDropAccepted,
    onDropRejected,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  );

  return (
    <div className={className}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Typography variant="h5">Drag and drop your Profit and Loss report here or click to browse</Typography>
      </div>
    </div>
  );
};

interface FileDropperProps {
  onDropAccepted: DropzoneOptions['onDropAccepted'];
  onDropRejected: DropzoneOptions['onDropRejected'];
  supportedFileTypes: string | string[];
  className?: string;
}
